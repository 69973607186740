<template>
  <div class="safe-space" v-if="showSafeSpace">
    <h2 class="safe-space__title" v-html="title" ref="title"></h2>
    <div class="safe-space__container" :class="[desktopScreen && cards.length < 4 ? 'align-center' : '']">
      <swiper class="swiper" ref="swiper" :options="safesapceswiperOptions" :class="{'three-slider' : cards.length >= 3}">
        <swiper-slide v-for="(item, index) in cards" class="safe-space__carousel" :data-index="index" :key="index + 0">
          <div class="safe-space__item">
            <div class="safe-space__image">
            <img :src="item.MobileImage" :alt="item.Title.replace(/<[^>]*>/g, '')" loading="lazy" />
          </div>
            <div class="safe-space__content">
              <p class="safe-space__subTitle"  v-html="decodeHtml(item.SubTitle)" v-if="item.SubTitle"></p>
              <a :href="item.ArticleUrl" @click="tagging(item.Title)" class="safe-space__anchor">
                <h3 class="safe-space__name" v-html="decodeHtml(item.Title)"></h3>
              </a>
              <div class="safe-space__content-category">
                <div class="safe-space__content-category_icon" v-if="item.CategoryIcon !== ''">
                  <img :src="item.CategoryIcon" :alt="item.Category">
                  <div class="safe-space__content-category_icon_text" v-if="item.Category">
                    <strong>{{ item.Category }}</strong>
                  </div>
                </div>
                <div class="safe-space__content-category_read"  v-if="item.ReadingTime !== ''">
                  <div class="safe-space__content-category_read-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <circle cx="9.99928" cy="10.0001" r="6.11654" stroke="white" stroke-width="1.35923"/>
                      <path d="M10 6.60202V10.5664H12.8317" stroke="white" stroke-width="1.35923"/>
                    </svg>
                  </div>
                  <div class="safe-space__content-category_read-text">
                    {{ item.ReadingTime }}
                  </div>
              </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div  class="carousel-navigation" v-show="((desktopScreen && cards.length > 3) || !desktopScreen)" >
        <div class="carousel__arrow carousel__arrow-safe-space--left" :class="{ 'disabled': swiperAtBegain }" @click="prev()">
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="32" viewBox="0 0 42 32" fill="none">
            <g filter="url(#filter0_b_17_13510)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15.8535L3.85356 12L4.56066 12.7071L1.9142 15.3535L41.8605 15.3535V16.3535L1.91422 16.3535L4.56066 19L3.85355 19.7071L0 15.8535Z" fill="black"/>
            </g>
            <defs>
              <filter id="filter0_b_17_13510" x="-124" y="-124" width="290" height="280" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="62"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_17_13510"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_17_13510" result="shape"/>
              </filter>
            </defs>
          </svg>
        </div>
        <div class="safe-sapce-swiper-pagination" @click="pagination()"></div>
        <div class="carousel__arrow carousel__arrow-safe-space--right" :class="{ 'disabled': swiperAtEnd }" @click="next()">
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="32" viewBox="0 0 42 32" fill="none">
            <g filter="url(#filter0_b_17_13509)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M42 15.8535L38.1464 12L37.4393 12.7071L40.0858 15.3535L0.13953 15.3535V16.3535L40.0858 16.3535L37.4393 19L38.1465 19.7071L42 15.8535Z" fill="black"/>
            </g>
            <defs>
              <filter id="filter0_b_17_13509" x="-124" y="-124" width="290" height="280" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="62"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_17_13509"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_17_13509" result="shape"/>
              </filter>
            </defs>
          </svg>
        </div>
      </div>  
    </div>
    <a :href="ctaLink" v-if="ctaLabel" :class="[ctaClass,(desktopScreen && cards.length > 3 || !desktopScreen) ? 'discoverLinkwithNav' : 'discoverLink']" @click="tagging()">{{ ctaLabel }}</a>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Pagination, Navigation]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: 'safe-space',
  components: { Swiper, SwiperSlide },
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    pageCategory: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    ctaClass: {
      type: String,
      default: ''
    },
    ctaLabel: {
      type: String,
      default: ''
    },
    ctaLink: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      swipeMethod:"",
      sliderIndex: "",
      desktopScreen: window.matchMedia('(min-width: 768px)').matches,
      isMobile: window.innerWidth <= 768,
      swiperAtEnd: false,
      swiperAtBegain: true,
      safesapceswiperOptions: {
        loop: false,
        initialSlide: 0,
        speed: 500,
        slidesPerView: 3,
        pagination: {
          el: ".safe-sapce-swiper-pagination",
          clickable: true,
          type: "bullets"
        },
        navigation: {
          nextEl: '.carousel__arrow carousel__arrow-safe-space--left',
          prevEl: '.carousel__arrow carousel__arrow-safe-space--right',
          hiddenClass: '.swiper-button-hidden',
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          360: {
            centeredSlides: true,
            slidesPerView: 1.32,
            spaceBetween: 24
          },
          320: {
            centeredSlides: true,
            slidesPerView: 1.25,
            spaceBetween: 24
          },
        },
      },
      showSafeSpace: true,
    }
  },
  mounted() {
      this.$refs.swiper.swiperInstance.on('slideChange', (event) => {
        this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
        this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
        if(this.touchEnd == true && this.swipeMethod != 'dot' && this.swipeMethod != 'arrow') {
          this.swipeMethod = 'swipe';
          this.sliderIndex = this.$refs.swiper.$swiper.activeIndex + 1 ; 
          this.navTagging();
        }else if(this.swipeMethod == 'dot'){
          this.sliderIndex = this.$refs.swiper.$swiper.activeIndex + 1;
          this.navTagging();
        }
      });
      this.$refs.swiper.swiperInstance.on('touchEnd', (event) => {
       this.touchEnd = true;
      });
      window.addEventListener('resize', () => {
        this.isMobile = window.innerWidth <= 768;
      });
      window.eventBus.$on('safeSpaceFilterSelected', (val) => {
        this.showSafeSpace = val;
      });
  },
  methods: {
    decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    },
    pagination(){
      this.swipeMethod = 'dot';
    },
    prev() {  
      this.$refs.swiper.$swiper.slidePrev();
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      const currentIndex = this.$refs.swiper.$swiper.realIndex;
      this.swipeMethod = 'arrow';
      this.sliderIndex = currentIndex + 1;
      this.navTagging();
    },
    next() {
      this.$refs.swiper.$swiper.slideNext();
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      const currentIndex = this.$refs.swiper.$swiper.realIndex;
      const slidesPerView = parseInt(this.safesapceswiperOptions.slidesPerView) || 1;
      const desiredIndex = currentIndex + slidesPerView-1;
      this.swipeMethod = 'arrow';
      this.sliderIndex = desiredIndex + 1;
      this.navTagging();
    },
    tagging(articleTitle = '') {
      let mainUrl = window.location.origin;
      let ctalink = this.ctaLink;
      const title = this.removeHtmlAndSpecialChars(this.title).toLowerCase();
      const ctaLabel = articleTitle
        ? this.removeHtmlAndSpecialChars(articleTitle).toLowerCase()
        : this.removeHtmlAndSpecialChars(this.ctaLabel).toLowerCase();
      let destinationUrl;
      if (ctalink == '/') {
        destinationUrl = mainUrl;
      } else {
        destinationUrl = ctalink ? mainUrl + ctalink : 'none';
      }
      AnalyticsHandler.pushDataLayer({
        event: 'uaevent',
        ecommerce: 'undefined',
        event_name: 'body_button_click',
        eventCategory: this.pageCategory.toLowerCase() + '::article',
        eventAction: `select::${title}`,
        eventLabel: ctaLabel + '::' + `${destinationUrl.toLowerCase()}`,
        cta_name: ctaLabel,
        link_url: destinationUrl.toLowerCase(),
        module_name: title,
      });
    },
    navTagging() {    
      let data = this.cards[this.sliderIndex-1];
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'ecommerce': 'undefined', 
        'event_name': 'slider_button_click',
        'eventCategory': this.pageCategory.toLowerCase(),
        'eventAction': 'select::slider navigation elements',
        'eventLabel': `${this.swipeMethod}::${this.removeHtmlAndSpecialChars(data.Title).toLowerCase()}::${this.sliderIndex}`,
        'cta_name': `${this.swipeMethod}::${this.removeHtmlAndSpecialChars(data.Title).toLowerCase()}::${this.sliderIndex}`,
        'module_name': 'slider navigate::'+this.swipeMethod,
      });
      this.swipeMethod = '';
    },
    removeHtmlAndSpecialChars(inputString) {
        // Create a DOMParser to parse the inputString as HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(inputString, 'text/html');
      
        // Get the plain text without HTML tags
        const plainText = doc.body.textContent || "";
        return plainText.replaceAll('\n', '');
    },
  },
}
</script>
<style lang="scss" src="./safespace.scss"></style>
