<template>
  <div class="multipopin">
    <div class="multipopin__container">
      <modal class="multipopin__container--modal" ref="modal">

        <div class="multipopin__container__title">
          <div class="multipopin__container__title--stamp_placeholder">
            <div class="multipopin__container__title--stamp" v-if="initialData.Stamp !== ''">{{
              truncate(initialData.Stamp, 25) }}
            </div>
          </div>
        </div>
        <div class="multipopin__container__header">
          <div class="multipopin__container__title--des" v-html="truncate(initialData.Title, 65)">
          </div>

          <div class="multipopin__container__imgplaceholder">
            <img :src="initialData.ImageUrl" :alt="initialData.ImageUrlAlt" />
          </div>
          <div class="multipopin__container__desplaceholder">
            <div class="multipopin__container__footer--description" v-html="truncate(initialData.Description, 185)">
            </div>
          </div>
        </div>
        <div class="multipopin__container__footer">

          <div class="multipopin__container__footer--cta_placeholder">
            <a @click="setCookie(), gaTagging(initialData.CtaTextMobile, initialData.CtaTextDesktop, initialData.CtaLink,initialData.ctalinkTarget)"
              :class="initialData.CtaColor === 'light' ? 'cta cta--light' : 'cta  multipopin__container__footer--cta__dark '"
              :href="initialData.CtaLink" :target="initialData.ctalinkTarget ? '_blank' : ''"
              v-if="initialData.CtaTextDesktop !== '' && !smallScreen && initialData.CtaLink !== null">
              {{ initialData.CtaTextDesktop }}
            </a>
            <div
              v-if="initialData.CtaTextMobile !== '' && initialData.Dsf === false && smallScreen && initialData.CtaLink !== null">
              <a @click="setCookie(), gaTagging(initialData.CtaTextMobile, initialData.CtaLink,initialData.ctalinkTarget)"
                :class="initialData.CtaColor === 'light' ? 'cta cta--light' : 'cta  multipopin__container__footer--cta__dark '"
                :href="initialData.CtaLink" :target="initialData.ctalinkTarget ? '_blank' : ''"> {{
                  initialData.CtaTextMobile }} </a>
            </div>
            <div v-if="initialData.CtaTextMobile !== '' && initialData.Dsf === true && smallScreen">
              <spotscan-embed @click.native="setCookie" :data-tenant="dsfData.Tenant"
                :data-application="initialData.DSFApplication" :data-customer="dsfData.Customer"
                :data-country="dsfData.Country" :data-environment="dsfData.Environment"
                :data-touchpoint="dsfData.Touchpoint" :data-type="dsfData.Type"
                :data-loadingbg="dsfData.Loadingbackgroundcolor" :data-loadingmsg="dsfData.Loadingmessage"
                csrf-token="$WEBSITE_TOKEN" :switch-ecom="dsfData.Switchecom"
                :data-dsf-tagging="dsfTagging(dsfData.EventProperties.service_name, dsfData.EventProperties.service_category, initialData.CtaTextMobile)"
                :switch-autostart="dsfData.Switchautostart" :data-cta-label="initialData.CtaTextMobile"
                :data-cta="initialData.CtaColor === 'light' ? 'cta cta--light' : 'cta multipopin__container__footer--cta__dark '"></spotscan-embed>
            </div>

          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from '@Feature/Modal/code/Scripts/components/modal';
import SpotscanEmbed from '@Feature/SpotscanEmbed/code/Scripts/components/spotscan-embed.vue';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
  name: 'multi-pop-in',
  components: { SpotscanEmbed, Modal },
  props: {
    initialData: { type: Object, required: true, default: '' },
    dsfData: { type: Object, required: true, default: '' },
    productInfo: { type: String, required: true, default: '' },

  },
  data() {
    return {
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      timeDelay: parseInt(this.initialData.DelaySetting) * 1000 || 1000,
      timeOut: null
    };
  },

  mounted() {
    window.eventBus.$on('multipopin-close', payload => {
      if (payload === true) {
        this.setCookie();
        document.body.classList.remove("multipopin-open");
      }
    });

    const cookieValue = this.getCookie('multipopin');
    if (cookieValue === '') {
      if (this.timeDelay > 0) {
        this.$nextTick(() => {
          this.timeOut = setTimeout(() => {
            this.$children[0].$data.showModal = true;
            document.body.classList.add("multipopin-open");
          }, this.timeDelay);
        })
      } else {
        this.$children[0].$data.showModal = true;
      }

    }

  },
  methods: {

    getCookie(cookieName) {
      var name = cookieName + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    setCookie() {
      document.body.classList.remove("multipopin-open");
      this.$children[0].$data.showModal = false;
      const cookiename = 'multipopin';
      const value = '200';
      var date = new Date();
      date.setTime(date.getTime() + (30 * 1000));
      document.cookie = cookiename + '=' + value + '; expires=' + date.toString() + '; path=/; SameSite=Strict';
    },
    truncate(inputString, maxLength) {
      const tagOrTextRegex = /(<[^>]+>)|([^<]+)/g;
      let match;
      let length = 0;
      let result = '';
      const openTags = [];

      while ((match = tagOrTextRegex.exec(inputString)) !== null && length < maxLength) {
        const [fullMatch, tag, text] = match;

        if (tag) {
          // Add the HTML tag to the result and handle opening/closing tags
          result += tag;

          // If it's an opening tag (excluding self-closing tags), push to the openTags stack
          if (/^<[^\/!][^>]*[^\/]>$/.test(tag)) {
            const tagName = tag.match(/^<(\w+)/)[1];
            openTags.push(tagName);
          }

          // If it's a closing tag, pop from the openTags stack
          if (/^<\/(\w+)/.test(tag)) {
            openTags.pop();
          }
        } else if (text) {
          // Add as much text as possible without exceeding maxLength
          if (length + text.length > maxLength) {
            result += text.slice(0, maxLength - length);
            if (length + text.length > 185) {
              if(result !=''){
                result += '...';
              }
              
            }
            length = maxLength;
          } else {
            result += text;
            length += text.length;
          }
        }
      }

      // Close any unclosed tags in reverse order
      while (openTags.length > 0) {
        const tagName = openTags.pop();
        result += `</${tagName}>`;
      }
      return result;
    },
    gaTagging(mcta = '', dcta='', link='',external='') {
      var cta = dcta;
      if (this.smallScreen) {
        cta = mcta;
      }
      var url =window.location.origin +link;
      if(external){
        url= link;
      }

      AnalyticsHandler.pushDataLayer({
        event: 'uaevent',
        event_name: 'body_button_click',
        ecommerce: 'undefined',
        eventCategory: '',
        eventAction: '',
        eventLabel: '',
        cta_name: `${cta.toLowerCase()}`,
        link_url: `${url}`,
        module_name: 'multipurpose pop up component',
        product_info: `${this.productInfo}`

      });
    },
    dsfTagging(service = '', category = '', cta = '') {
      return {
        event: "uaevent",
        ecommerce: "undefined",
        event_name: "site_load_service",
        eventCategory: " ",
        eventAction: " ",
        eventLabel: " ",
        service_name: `${service}`,
        service_category: `${category}`,
        cta_name: `${cta.toLowerCase()}`
      };
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  }
};
</script>

<style lang='scss' src="./multi-pop-in.scss"></style>
